@tailwind base;
@tailwind components;
@tailwind utilities;
@import './themes/dark.css';

@layer base {
  :root {
    /* main */
    --primary: #0D0D0D;
    --secondary: #FFFFFF;
    --accent: #3519E9;
    --foreground: #0D0D0D;
    --background: #F9F9F9;

    /* greyscale */
    --white: #FFFFFF;
    --black: #0D0D0D;
    --grey-100: #F0F0F0;
    --grey-200: #E4E4E7;
    --grey-300: #C7C7C7;
    --grey-400: #828282;
    --grey-500: #494949;
    --grey-600: #2A2A2A;

    /* whitescale */
    --white-100: #FFFFFF0F;
    --white-200: #FFFFFF14;
    --white-300: #FFFFFF1F;
    --white-400: #FFFFFF29;
    --white-500: #FFFFFF3D;
    --white-600: #FFFFFF52;
    --white-700: #FFFFFF66;

    /* bluescale */
    --blue-100: #ECEAFF;
    --blue-200: #C3BAFF;
    --blue-300: #9A8AFF;
    --blue-400: #715BFF;
    --blue-500: #482BFF;
    --blue-600: #3519E9;
    --blue-700: #2309C7;
    --blue-800: #1600A5;
    --blue-900: #120083;

  /* Success */
  --success: #37CD7C;
  --success-primary: var(--success);
  --success-secondary: #20B866;
  --success-background: #E2F7EC;

  /* Warning */
  --warning: #EF9038;
  --warning-primary: var(--warning);
  --warning-secondary: #D07623;
  --warning-background: #FCEDDF;

  /* Error */
  --error: #F4434E;
  --error-primary: var(--error);
  --error-secondary: #D52630;
  --error-background: #FCE3E4;

    --radius: 0.5rem;
  }
}

@layer base {
  body {
    @apply text-primary w-screen;
  }

  html,
  body,
  main {
    @apply h-screen;
    @supports (-webkit-touch-callout: none) {
      height: 100dvh;
    }
  }
}

@layer components {
  /* Typography */

  h1 {
    @apply text-5xl leading-5xl font-bold font-body;
  }
  h2 {
    @apply text-4xl leading-4xl font-bold font-body;
  }
  h3 {
    @apply text-3xl leading-3xl font-bold font-body;
  }
  h4 {
    @apply text-2xl leading-2xl font-bold font-body;
  }
  h5 {
    @apply text-xl leading-xl font-bold font-body;
  }
  h6 {
    @apply text-md leading-lg font-bold font-body;
  }

  .heading-1 {
    @apply text-5xl leading-5xl font-bold font-body;
  }
  .heading-2 {
    @apply text-4xl leading-4xl font-bold font-body;
  }
  .heading-3 {
    @apply text-3xl leading-3xl font-bold font-body;
  }
  .heading-4 {
    @apply text-2xl leading-2xl font-bold font-body;
  }
  .heading-5 {
    @apply text-xl leading-xl font-bold font-body;
  }
  .heading-6 {
    @apply text-md leading-lg font-bold font-body;
  }

  .subtitle-1 {
    @apply text-md leading-lg font-semibold font-body;
  }
  .subtitle-2 {
    @apply text-sm leading-sm font-semibold font-body;
  }
  .subtitle-3 {
    @apply text-xs leading-xs font-semibold font-body;
  }

  .body-1 {
    @apply text-lg leading-xl font-normal font-body;
  }
  .body-2 {
    @apply text-md leading-lg font-normal font-body;
  }
  .body-3 {
    @apply text-sm leading-sm font-normal font-body;
  }
  .body-4 {
    @apply text-xs leading-xs font-normal font-body;
  }

  .breadcrumb {
    @apply text-sm leading-sm font-normal font-highlight;
  }

  .menu-1 {
    @apply text-md leading-md font-normal font-highlight;
  }
  .menu-2 {
    @apply text-xs leading-xs font-light font-highlight;
  }

  body {
    @apply body-2;
  }

  /* Focus */
  .focusable {
    @apply rounded-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2;
  }

  .desktop-only-text {
    @apply !hidden md:!inline
  }
  .mobile-only-text {
    @apply !inline md:!hidden
  }
  .desktop-only-block {
    @apply !hidden md:!block
  }
  .mobile-only-block {
    @apply !block md:!hidden
  }
  .desktop-only-flex {
    @apply !hidden md:!flex
  }
  .mobile-only-flex {
    @apply !flex md:!hidden
  }
}

/*reset chrome*/
p {
  margin-block-start: 0!important;
}

a {
  margin-top: 0!important;
}

h4 {
  margin: 0!important
}
